import moment from 'moment'
import { LotBidDTO, TimeIntervalDTO } from '@/services/tender/types'

export const formatDate = (date: string): string => {
  return moment.utc(date).format('DD/MM/YYYY')
}

export const formatTime = (time: string): string => {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}

export const formatWeekday = (date: string): string => {
  return moment.utc(date).format('dddd')
}

export const errorMessages = (messages: string | string[]): string => {
  if (typeof messages === 'string') return messages
  return messages.join('<br>')
}

export const sortLots = (lots: LotBidDTO[]): LotBidDTO[] => {
  lots.sort((a, b) => {
    return a.id - b.id
  })
  return lots
}

export const renderDeliveryProfile = (deliveryProfile: TimeIntervalDTO[]): string => {
  return deliveryProfile.map((profile) => `${profile.startTime} - ${profile.endTime}`).join(' & ')
}

export const formatEvaluationTime = (hour: number, minutes: number): string =>
  `${hour ? hour + '(h) :' : ''} ${minutes}(m)`

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const formatMoney = (money: number): string =>
  new Intl.NumberFormat(`de-EN`, {
    currency: `EUR`,
    style: 'currency',
  }).format(money)

export const parseAndJoin = (str: string): string => {
  if (isJsonString(str)) return JSON.parse(str).join()
  return str
}

export const today = (): string => moment(new Date()).format('YYYY-MM-DD')
